@import 'variables';

.corporation-form {
  &__input, &__input--select, &__input--checkbox {
    margin-bottom: 10px;
  }

  &__input {
    width: 100%;
  }
  
  &__input--checkbox {
    transform: scale(1.5);
  }
}
